'use client';

import Link from 'next/link';
import {
  Box, Grid, SxProps, Typography,
} from '@mui/material';

import Logo from '@/components/Logo';
import styles from './Footer.module.scss';
import theme from '@/theme';

const Footer = ({ sx }: { sx?: SxProps }) => {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      component='footer'
      sx={sx}
    >
      <div className={styles.wrapper}>
        <Box
          sx={{
            marginRight: 'auto',
            [theme.breakpoints.up('lg')]: {
              marginRight: 30,
            },
          }}
          className={styles.logoWrapper}
        >
          <Link
            href='/'
            aria-label='Get back to main page'
            prefetch={false}
          >
            <Logo />
          </Link>
        </Box>

        <Grid
          container
          spacing={{ sm: 3, lg: 0 }}
        >
          <Grid
            item
            xs={12}
            lg={4}
            marginTop={{ xs: 0, lg: 1 }}
          >
            Need Help?
            <a
              href='mailto:contact@propjump.com?subject=Help'
              target='_blank'
              rel='noreferrer'
            >
              <span className='primary-text'> Contact us</span>
            </a>
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
            marginTop={{ xs: 1, lg: 1 }}
          >
            <a
              href='mailto:feedback@propjump.com?subject=Feedback'
              target='_blank'
              rel='noreferrer'
              className='primary-text'
            >
              Feedback
            </a>
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
            marginTop={{ xs: 1, lg: 1 }}
          >
            <Link
              href='/add-listing'
              aria-label='Find customers'
              prefetch={false}
            >
              <Typography
                variant='body1'
                fontWeight={400}
                component='span'
                className='primary-text'
              >
                Add Your Business to PropJump
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </div>
      <div className={styles.rights}>
        <Typography
          variant='body1'
          fontWeight={400}
          component='span'
        >
          ©
          {' '}
          {currentYear}
          {' '}
          All Rights Reserved
        </Typography>
        <Typography
          variant='body1'
          component='div'
          fontWeight={400}
          display='flex'
          marginTop={{ xs: 3, lg: 0 }}
        >
          Designed by
          <Link
            href='https://www.hands.studio'
            target='_blank'
            className={styles.designLink}
            aria-label='Footer link'
            prefetch={false}
          >
            Hands Studio
          </Link>
        </Typography>
      </div>
    </Box>
  );
};
export default Footer;
