import Img from '@/components/Img';

import styles from './Logo.module.scss';

const Logo = () => (
  <div className={styles.wrapper}>
    <Img
      src='/media/horizontal-logo.webp'
      alt='Logo'
      width={164}
      height={32}
      priority
    />
  </div>
);

export default Logo;
