'use client';

import Link from 'next/link';
import { Button, Box } from '@mui/material';

import Logo from '@/components/Logo';
import theme from '@/theme';
import SearchForm from '@/components/SearchForm';
import SearchHeaderControls from '@/page-components/Search/SearchHeader/SearchHeaderControls';
import { headerControlsHeight } from '@/constants';

const SearchHeader = () => (
  <header className='header-container'>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      }}
    >
      <Link
        href='/'
        aria-label='Get back to main page'
        style={{
          marginRight: '35px',
        }}
        prefetch={false}
      >
        <Logo />
      </Link>
    </Box>
    <Box
      display='block'
      marginRight='auto'
      sx={{
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      }}
    >
      <SearchForm />
    </Box>

    <Box
      width='100%'
      sx={{
        display: 'block',
        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      }}
    >
      <SearchHeaderControls />
    </Box>

    <Box
      sx={{
        display: 'none',
        [theme.breakpoints.up('lg')]: {
          display: 'block',
        },
      }}
    >
      <Link
        href='/add-listing'
        aria-label='Go to listing'
        legacyBehavior
        passHref
        prefetch={false}
      >
        <Button
          href='/add-listing'
          variant='outlined'
          sx={{
            height: headerControlsHeight,
            width: 197,
            display: 'flex',
          }}
        >
          Add Your Business
        </Button>
      </Link>
    </Box>
  </header>
);

export default SearchHeader;
