import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { Button, Typography, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SearchHeaderControls.module.scss';
import { colorVariables } from '@/constants';
import Img from '@/components/Img';
import { SearchLayoutContext } from '@/page-components/Search/SearchLayout/SearchLayout';
import theme from '@/theme';
import SearchDrawer from '../../SearchDrawer/SearchDrawer';
import FiltersDrawer from '../../FiltersDrawer/FiltersDrawer';
import { useQuery } from '@/hooks/useQuery';
import { ServicesLayoutContext } from '@/page-components/Services/ServicesLayout/ServicesLayout';

const SearchHeaderControls = () => {
  const isMobileSearchPlaceholder = useMediaQuery(theme.breakpoints.down(425));
  const path = usePathname();
  const isSearch = path.startsWith('/hire');
  // @ts-ignore
  const { location, term } = useContext(isSearch ? SearchLayoutContext : ServicesLayoutContext);
  const { queryParams } = useQuery();
  const buttonText = useMemo(() => {
    if (location && term) return `${term} in ${location.name}`;
    if (location) return `In ${location.name}`;
    if (term) return term;
    return isMobileSearchPlaceholder ? 'How can we help?' : 'What do you need help with?';
  }, [location, term, isMobileSearchPlaceholder]);

  const [openSearchDrawer, setOpenSearchDrawer] = useState(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [isFiltersButtonVisible, setIsFiltersButtonVisible] = useState(false);
  const pathItems = path.split('/');

  useEffect(() => {
    const stateIndex = pathItems.findIndex(item => item.length === 2);
    const state = stateIndex !== -1 ? pathItems[stateIndex] : '';
    const city = stateIndex !== -1 ? pathItems[stateIndex + 1] : '';

    if (queryParams.has('loc') || (state?.length === 2 && city?.length)) {
      setIsFiltersButtonVisible(true);
    } else {
      setIsFiltersButtonVisible(false);
    }
  }, [queryParams.get('loc'), path]);

  return (
    <header className={styles.wrapper}>
      <Link
        href='/'
        aria-label='Get back to main page'
        className={styles.link}
        prefetch={false}
      >
        <Img
          src='/media/brandmark-logo.webp'
          alt='Logo icon'
          height={25}
          width={25}
          priority
        />
      </Link>
      <Button
        variant='outlined'
        sx={{
          border: `1px solid ${colorVariables.separatorColor}`,
          color: 'unset',
          textTransform: 'none',
          flexGrow: 1,
          paddingRight: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={() => setOpenSearchDrawer(true)}
      >
        <Typography
          fontWeight={400}
          fontSize={16}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          color={colorVariables.textDescription}
        >
          {buttonText}
        </Typography>
        <SearchIcon
          color='success'
          sx={{ marginLeft: 2 }}
        />
      </Button>

      {isFiltersButtonVisible && (
        <Typography
          color={colorVariables.primaryColor}
          onClick={() => setOpenFiltersDrawer(true)}
          fontWeight={600}
          fontSize={14}
          marginLeft={2}
        >
          Filters
        </Typography>
      )}

      <SearchDrawer
        open={openSearchDrawer}
        toggleDrawer={setOpenSearchDrawer}
      />
      <FiltersDrawer
        open={openFiltersDrawer}
        toggleDrawer={setOpenFiltersDrawer}
      />
    </header>
  );
};

export default SearchHeaderControls;
