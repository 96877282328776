'use client';

import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
} from '@mui/material';
import { usePathname } from 'next/navigation';
import { colorVariables } from '@/constants';
import { getCategoryInfo, getLocationSearch } from '@/lib/api/functions';
import { Category } from '@/lib/types/interfaces/Category';
import { useQuery } from '@/hooks/useQuery';
import { City } from '@/lib/types/interfaces/City';
import SearchHeader from '@/page-components/Search/SearchHeader';
import Footer from '@/components/Footer';

interface SearchContext {
  currentCategory?: Category | null;
  location?: City | null;
  term?: string | null;
}

export const ServicesLayoutContext = createContext<SearchContext>({
  location: null,
  term: null,
  currentCategory: null,
});

const ServicesLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const { queryParams } = useQuery();
  const path = usePathname();
  const pathItems = path.split('/');

  const [term, setTerm] = useState<string | null>(queryParams.get('term')?.toString() || '');
  const [location, setLocation] = useState<City | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const fetchData = useCallback(async () => {
    try {
      let category = '';
      let subcategory = '';

      if (pathItems.length > 1 && pathItems[1] === 'services') {
        category = pathItems?.[2];

        if (pathItems?.length > 2) {
          if (pathItems?.[3]?.length !== 2) {
            subcategory = pathItems?.[3];
          }
        }
      }

      const categoryInfo = await getCategoryInfo(subcategory || category);

      setCurrentCategory(categoryInfo || null);

      const hasTerm = queryParams.get('term')?.toString();

      if (hasTerm) {
        return;
      }

      if (categoryInfo) {
        setTerm(categoryInfo.name);
      }
    } catch (err) {
      console.error(err);
    }
  }, [path]);

  const fetchLocation = useCallback(async () => {
    let state;
    let city;

    const stateIndex = pathItems.findIndex(item => item.length === 2);

    if (stateIndex !== -1) {
      state = pathItems[stateIndex];
      city = pathItems[stateIndex + 1];
    }

    if (city && state) {
      try {
        const response = await getLocationSearch(city);

        setLocation(
          response?.data
            .find((loc) => loc.state === state && loc.name === decodeURIComponent(city).replaceAll('_', ' '))
          || null,
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      setLocation(null);
    }
  }, [path]);

  useEffect(() => {
    fetchData();
    fetchLocation();
  }, [path]);

  return (
    <ServicesLayoutContext.Provider value={useMemo(() => ({
      term,
      location,
      currentCategory,
    }), [currentCategory, term, location])}
    >
      <SearchHeader />
      <main>
        <Grid
          container
          flex={1}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              borderLeft: `1px solid ${colorVariables.separatorColor}`,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </Grid>
        </Grid>
      </main>
      <Footer />
    </ServicesLayoutContext.Provider>
  );
};
export default ServicesLayout;
